import editObj from "../../../assets/icons/EditObj.svg";
import { useAbleFortalezasDebilidades } from "../../../hooks/FortalezasDebilidades";
import {
  EditAreasContainer,
  EditContainer,
} from "../Dashboard/stylesDashboard/AdminStyles";

const EditStrengthsAndOpportunities = ({
  setEditObjective,
  index,
  data,
  setIndexEdit,
  setIdSelect,
  inputModal,
  textModal,
  setTextModal,
  setValue,
}: any) => {
  const { enablePermissionStrAndOp } = useAbleFortalezasDebilidades();
  const principalFilters = localStorage.getItem("principalFilters");
  const filtersParsed = JSON.parse(principalFilters ?? "{}");
  const currentYear: number = new Date().getFullYear();
  const { year } = filtersParsed;
  const handleClick = () => {
    if(year != currentYear.toString()) return;
    setTextModal(
      (data[index] &&
        data[index]?.employeeEvaluationWorkersStrengthsQuestionText) ||
        data[index]?.employeeEvaluationWorkersOpportunitieAreaText
    );

    setValue(
      (data[index] &&
        data[index]?.employeeEvaluationWorkersStrengthsQuestionText) ||
        data[index]?.employeeEvaluationWorkersOpportunitieAreaText
    );
    setEditObjective(true);
    setIndexEdit(index);
    setIdSelect(index);
  };

  return (
    <>
      <EditContainer>
        <EditAreasContainer>
          {(data[index] &&
            data[index]?.employeeEvaluationWorkersStrengthsQuestionText) ||
            data[index]?.employeeEvaluationWorkersOpportunitieAreaText}
        </EditAreasContainer>
        {!enablePermissionStrAndOp && (
          <div style={{  cursor: year == currentYear.toString() ? 'pointer' : 'default' }} onClick={() => handleClick()}>
            <img
              src={editObj}
              alt="EditStrengthsAndOpportunities"
              style={{ height: "12px", width: "12px" }}
            />
          </div>
        )}
      </EditContainer>
    </>
  );
};

export default EditStrengthsAndOpportunities;
