import { useContext, useEffect, useLayoutEffect, useState, useRef } from "react";
import {
  FiltersDashboardContext,
  FiltersDashboardType,
} from "../../../../context/ManageFiltersDashboard/FiltersDashboard";
import { validateIdPeriod, validateSemestre } from "../../../../context/validacionesFiltros";
import { useCollaboratorRedux } from "../../../../redux/Collaborators";
import { getDataChartsPie } from "../../../../services/dataSelects.service";
import {
  ButtonFilterDashboard,
  FilterWrapper,
  SelectLabelDashboard,
  SelectPeriodDashboard,
} from "./FilterDashboardStyles";
import { useLoader } from "../../../../context/LoaderContext";

const titleDonuts = [
  // dashboard
  "Calificación promedio de todos los trabajadores",
  "Colaboradores con calificación superior al 95%",
  "Colaboradores con calificación Inferior al 50%",
  // politicas
  "Colaboradores que han presentado su evaluación de políticas",
  "Colaboradores con evaluación sin concluir",
  "Colaboradores que se han dado de baja",
];

const NewFilterDashboard = ({
  setSemestre,
  handleSelectPeriod,
  setEvaluation_period_id,
   // setDirections,
  // locations,
  // directions,
  // handleOptionLocation,
  // handleOptionDirector,
  setDataGraph,
  dataGraph,
  setIsSelectLocalidadUpdated,
  isSelectLocalidadUpdated,

  propLocation,
  propDirector,
  setPropLocation,
  setPropDirector,
  setDataRestante,
  fetchData,
}: any) => {
  //**  Context
  const {
    onChangeOptionYear,
    onChangeOptionSemestre,
    selectedOptionPeriod,
    selectedOptionYear,
    years,
    semesterOptions,
    getYearsOptions,
    setSelectedOptionYear,
    getPeriods,
    locations,
    directions,
    getOptionsDirections,
    getOptionisLocations,
    onChangeOptionSemestreLocal,
    setSelectedOptionPeriod,
    getOnlyYears,
    getOnlyPeriods
  } = useContext(FiltersDashboardContext) as FiltersDashboardType;

  const { incrementRequests, decrementRequests } = useLoader();

  const handlePaintData = (periodValidate: any) => {
    const getDataDonut = async () => {
      let requestData;

      try {
        incrementRequests();
        if (propLocation !== "default" && propDirector !== "default") {
          requestData = await getDataChartsPie(
            periodValidate,
            User.claTrab,
            propLocation,
            propDirector
          );
        } else {
          requestData = await getDataChartsPie(periodValidate, User.claTrab);
        }

        const result = requestData.data.map((data, index) => ({
          title: titleDonuts[index],
          value: data.compleado,
        }));

        setDataGraph(result);

        const rest = requestData.data.map((data, index) => ({
          title: titleDonuts[index],
          value: data.restante,
        }));

        setDataRestante(rest);
      } catch (error) {
        console.error('Error fetching data for donut chart:', error);
      } finally {
        decrementRequests();
        setIsSelectLocalidadUpdated(true);
      }
    };

    getDataDonut();
  };
  //**  Redux
  const { User, evaluationAttribute } = useCollaboratorRedux();
  const [counter, setCounter] = useState(0);
  const [loaderFilters, setLoaderFilters] = useState(true);
  const searchButtonRef = useRef<HTMLButtonElement>(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const handleSearchPeriod = () => {
    const filtrosAlmacenados = localStorage.getItem("principalFilters");
    const periodId = localStorage.getItem("periodId");

    if(!selectedOptionPeriod){
      setSelectedOptionPeriod(periodId)
    }

    const periodIdSearch = { valuePeriod: selectedOptionPeriod };
    localStorage.setItem("periodId", JSON.stringify(periodIdSearch));


    //! Setea el periodo en el que se encuentra el usuario
    setSelectedOptionPeriod(selectedOptionPeriod);
    const periodValidate = validateIdPeriod(selectedOptionPeriod);
    handleSelectPeriod(periodValidate);


    //! Setea el semestre en el que se encuentra el usuario
    const semestre = validateSemestre(selectedOptionPeriod)
    setSemestre(semestre);

    fetchData(User.nombre, periodValidate);

    const obj = {
      year: selectedOptionYear,
      periodo: periodValidate,
      propLocation: "default",
      propDirector: "default",
    };
      // console.log(obj);

    localStorage.setItem("principalFilters", JSON.stringify(obj));
    setEvaluation_period_id(periodValidate);
    handlePaintData(periodValidate);
  // }
  };

  const handleCleanPeriod = (e: any) => {
    e.preventDefault();

    //! Periodo
    fetchData(User.nombre, User.periodInfo.idPeriod);
    setSelectedOptionYear(User?.periodInfo?.year);
    getPeriods(User?.periodInfo?.year, true);

    //! Localidad

    setDataGraph([{ title: "", value: "" }]);
    setIsSelectLocalidadUpdated(false);

    setPropLocation("default");
    setPropDirector("default");
    setCounter(counter + 1);
    setEvaluation_period_id(
      `${User.periodInfo.idPeriod},${User.periodInfo.semesterNumber}`
    );
    const obj = {
      year: User?.periodInfo?.year,
      periodo: User?.periodInfo?.idPeriod,
      propLocation: "default",
      propDirector: "default",
    };
    // console.log(obj);
    const periodId = {
      valuePeriod: `${User.periodInfo.idPeriod},${User.periodInfo.semesterNumber}`,
    };

    localStorage.setItem("periodId", JSON.stringify(periodId));

    localStorage.setItem("principalFilters", JSON.stringify(obj));
  };

  //   useEffect(() => {
  //     getYearsOptions();
  //   }, [User]);

  useEffect(() => {
    // Recupera los filtros almacenados en el localStorage al cargar la aplicación
    setLoaderFilters(false);
    getOnlyYears();
    const filtrosAlmacenados = localStorage.getItem("principalFilters");
    const periodId = localStorage.getItem("periodId");

    if (filtrosAlmacenados && periodId) {
      const filtros = JSON.parse(filtrosAlmacenados);
      const periodParsed = JSON.parse(periodId);

      const { valuePeriod } = periodParsed;
      const { year, periodo, propLocation, propDirector } = filtros;

      // console.log(
      //   "Id del periodo",
      //   valuePeriod,
      //   "Periodo parseado",
      //   selectedOptionPeriod
      // );

      if (year && periodo && valuePeriod) {
        setSelectedOptionYear(year);
        getOnlyPeriods(year);
        fetchData(User.nombre, periodo);
        handleSelectPeriod(periodo);
        setSemestre(periodo);
        setSelectedOptionPeriod(valuePeriod);
        onChangeOptionSemestreLocal(valuePeriod);
        setEvaluation_period_id(valuePeriod);
      }

      setTimeout(() => {
        setLoaderFilters(true);
      }, 1000);
    } else {
      getYearsOptions();
      setTimeout(() => {
        setLoaderFilters(true);
      }, 1000);
    }

    if (searchButtonRef.current) {
      searchButtonRef.current.click();
    }
  }, []);

  useEffect(() => {
    if (years.length > 0 && isInitialLoad) {
      // Primero verificamos si hay un año guardado en el localStorage
      const savedYear = localStorage.getItem("selectedYear");
      const savedPeriod = localStorage.getItem("selectedPeriod");

      let defaultYear;
      if (savedYear) {
        defaultYear = parseInt(savedYear, 10);
      } else {
        // Si no hay un año guardado, usamos el más reciente (2024)
        const sortedYears = years.sort((a: { year: number }, b: { year: number }) => b.year - a.year);
        defaultYear = sortedYears[0]?.year;
      }

      setSelectedOptionYear(defaultYear);
      onChangeOptionYear({ target: { value: defaultYear } });

      if (semesterOptions[0]?.evaluationPeriodId) {
        const defaultPeriod = savedPeriod ? savedPeriod : semesterOptions[0].evaluationPeriodId;

        onChangeOptionSemestre({ target: { value: `${defaultPeriod},${semesterOptions[0].evaluationPeriodNumberOfYear}` } });

        if (searchButtonRef.current) {
          searchButtonRef.current.click();
        }

        setIsInitialLoad(false);
      }
    }
  }, [years, semesterOptions, isInitialLoad]);

  // Guardar el año seleccionado en el localStorage cuando el usuario lo cambia
  useEffect(() => {
    if (selectedOptionYear) {
      localStorage.setItem("selectedYear", selectedOptionYear.toString());
    }
  }, [selectedOptionYear]);

  // Guardar el periodo seleccionado en el localStorage cuando el usuario lo cambia
  useEffect(() => {
    if (selectedOptionPeriod) {
      localStorage.setItem("selectedPeriod", selectedOptionPeriod.toString());
    }
  }, [selectedOptionPeriod]);


  useLayoutEffect(() => {


    if(semesterOptions && semesterOptions.length > 0 && selectedOptionPeriod){

      const period = selectedOptionPeriod;
      const periodValidate = validateIdPeriod(selectedOptionPeriod);

      fetchData(User.nombre, periodValidate);
      const semestre = validateSemestre(period);
      setSemestre(semestre);

      fetchData(User.nombre, periodValidate);

      const obj = {
        year: selectedOptionYear,
        periodo: periodValidate,
        propLocation: "default",
        propDirector: "default",
      };

      const periodIdSearch = { valuePeriod: period };
      localStorage.setItem("periodId", JSON.stringify(periodIdSearch));

      setEvaluation_period_id(periodValidate);
      handlePaintData(periodValidate);

      localStorage.setItem("principalFilters", JSON.stringify(obj));
    }
  }, [semesterOptions]);

  return (
    <div style={{ paddingBottom: "24px", borderBottom: "2px solid #F0F0F0" }}>
      <FilterWrapper>
        <div className="labelBoxTab">Filtros</div>
        <div className="containerSelectsAndBtns">
          {loaderFilters ? (
            <div className="onlySelects">
              <SelectLabelDashboard>
                <div className="containerTxt">Año</div>

                <SelectPeriodDashboard
                  value={selectedOptionYear}
                  onChange={(e: any) => onChangeOptionYear(e)}
                  name="year"
                >
                  {years.map(({ year }: any) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </SelectPeriodDashboard>
              </SelectLabelDashboard>

              <SelectLabelDashboard id="semester">
                <div className="containerTxt">Semestre</div>

                <SelectPeriodDashboard
                  value={selectedOptionPeriod}
                  onChange={(e: any) => onChangeOptionSemestre(e)}
                  name="semester"
                >
                  {semesterOptions.map((periodName: any) => (
                    <option
                      key={periodName?.evaluationPeriodId}
                      value={`${periodName?.evaluationPeriodId},${periodName?.evaluationPeriodNumberOfYear}`}
                    >
                      {periodName?.evaluationPeriodNumberOfYear}
                    </option>
                  ))}
                </SelectPeriodDashboard>
              </SelectLabelDashboard>

              {evaluationAttribute.kpis_evaluation_politics === 1 &&
                evaluationAttribute.kpis_evaluation === 1 && (
                  <>
                    <SelectLabelDashboard>
                      <div className="containerTxt">Localidad</div>

                      <SelectPeriodDashboard
                        value={propLocation}
                        onChange={(e: any) => setPropLocation(e.target.value)}
                        name="localidad"
                      >
                        <option style={{ color: "black" }} value="default">
                          Selecciona
                        </option>
                        {locations.map((data: any) => {
                          return (
                            <option
                              style={{ color: "black" }}
                              key={data}
                              value={data}
                            >
                              {data}
                            </option>
                          );
                        })}
                      </SelectPeriodDashboard>
                    </SelectLabelDashboard>

                    <SelectLabelDashboard>
                      <div className="containerTxt">Director</div>

                      <SelectPeriodDashboard
                        value={propDirector}
                        onChange={(e: any) => setPropDirector(e.target.value)}
                        name="director"
                      >
                        <option style={{ color: "black" }} value="default">
                          Selecciona
                        </option>
                        {directions.map((data: any) => {
                          return (
                            <option
                              style={{ color: "black" }}
                              key={data}
                              value={data}
                            >
                              {data}
                            </option>
                          );
                        })}
                      </SelectPeriodDashboard>
                    </SelectLabelDashboard>
                  </>
                )}
            </div>
          ) : (
            <>Cargando</>
          )}
          <div className="onlyBtns">
            <ButtonFilterDashboard
              onClick={handleCleanPeriod}
              colorBg="#C9F2FF"
              colorFont="#3B63A2"
            >
              Limpiar
            </ButtonFilterDashboard>
            <ButtonFilterDashboard
              id="search"
              ref={searchButtonRef}
              onClick={handleSearchPeriod}
              colorFont="#FFF"
            >
              Buscar
            </ButtonFilterDashboard>
          </div>
        </div>
      </FilterWrapper>
    </div>
  );
};

export default NewFilterDashboard;
