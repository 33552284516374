import { useContext, useEffect, useState } from "react";
import { IGetIsCreatingEvaluationsResponse, IPeriod } from "../../Interface/ApiInterface";
import { HeaderTitleDos } from "../../components/atoms/EvaluationHeader/EvaluationHeaderStyles";
import PaginationTable from "../../components/atoms/PaginationTable";
import ModalPerioEvaluacion from "../../components/common/ModalPerioEvaluacion";
import Caution from "../../components/organisms/ConfirmationModals/Caution";
import ModalStatusTargetError from "../../components/organisms/ConfirmationModals/ModalStatusError";
import ModalStatusSuccessful from "../../components/organisms/ConfirmationModals/ModalStatusSuccessful";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { usePeriods } from "../../redux/Periods";
import {
  BackWhiteQ,
  ContainPeriodoFix,
  ContainerTable,
  ContainerTableFixed,
  PaginationBox,
  WrapperPeriodos,
  WrapperPeriodosBody,
  WrapperPeriodosHeader,
} from "./Dashboard/stylesDashboard/AdminStyles";
import {
  CellHeadingText,
  ContainPageQuestionaire,
  RowBox,
  Title
} from "./Objectives/ObjetivesStyles.js";
import { SocketContext } from "../../context/SocketContext";
import ModalLoader from "../../components/organisms/ConfirmationModals/ModalLoader";
import colladoLoader from '../../assets/colladoLoader.gif';

interface PeriodState {
  isInProgress: boolean;
  isError: boolean;
}

const PeriodosEvaluaciones = () => {
  const { User } = useCollaboratorRedux();
  const [period, setPeriod] = useState({} as IPeriod);
  const [submitModal, setSubmitModal] = useState(false);
  const [showModalCaution, setShowModalCaution] = useState(false);
  const [periodId, setPeriodId] = useState(0);
  const [periodStates, setPeriodStates] = useState<{ [key: number]: PeriodState }>({});

  const {
    Meta,
    periods,
    handlePostPeriod,
    handleGetAllPeriods,
    setErrorPost,
    setSuccesPost,
    errorPost,
    succesPost,
    message,
    loader,
    handleCreateEvaluationPeriods,
  } = usePeriods();
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    if (submitModal) {
      handlePostPeriod(period, User.claTrab);
      handleGetAllPeriods(``);
      setPeriod({
        ...period,
        evaluationPeriodYear: 0,
        evaluationPeriodName: "",
      });
      setSubmitModal(false);
    }
  }, [submitModal]);

  useEffect(() => {
    handleGetAllPeriods(``);
  }, []);

  const activeCautionModal = (item: IPeriod) => {
    setPeriodId(item.evaluationPeriodId);
    setShowModalCaution(true);
  };

  const handleEmitFinishedEvaluation = () => {
    socket?.emit('finished-evaluations');
  };

  useEffect(() => {
    if (socket) {
      socket?.emit('join-periods');
    }

    return () => {
      if (socket) {
        socket.off('inprogress-evaluations');
        socket.off('finished-evaluations-process');
        socket.off('canceled-evaluations-process');
      }
    };
  }, [socket]);

  const handleCreateEvaluation = async (periodId: number) => {
    // Actualizar el estado a "En progreso" para el periodo específico
    setPeriodStates(prev => ({
      ...prev,
      [periodId]: { isInProgress: true, isError: false },
    }));

    try {
      await handleCreateEvaluationPeriods(periodId, User.claTrab);
      handleEmitFinishedEvaluation();
      await handleGetAllPeriods(``);
      // Marcar como completado si se finaliza sin errores
      setPeriodStates(prev => ({
        ...prev,
        [periodId]: { isInProgress: false, isError: false },
      }));
    } catch (error) {
      // Manejar el error y actualizar el estado a "Error"
      setPeriodStates(prev => ({
        ...prev,
        [periodId]: { isInProgress: false, isError: true },
      }));
    }
  };

  const { evaluationAttribute } = useCollaboratorRedux();
  return (
    <ContainPageQuestionaire>
      <HeaderTitleDos>Períodos y evaluaciones</HeaderTitleDos>
      <RowBox>
        <Title
          style={{
            fontSize: "20px",
            marginBottom: "1rem",
          }}
        >
          Periodo
        </Title>
      </RowBox>
      <ContainPeriodoFix>
        {evaluationAttribute.create_period === 1 && (
          <ModalPerioEvaluacion
            setPeriod={setPeriod}
            period={period}
            setSubmitModal={setSubmitModal}
          />
        )}
        {succesPost ? (
          <ModalStatusSuccessful
            handleClick={setSuccesPost}
            modalStatus={succesPost}
            messageShow
            message={message}
            titleModal={
              message === "El periodo ha sido creado correctamente."
                ? "Periodo creado"
                : "Evaluación creada"
            }
          />
        ) : null}
        {errorPost ? (
          <ModalStatusTargetError
            handleClick={setErrorPost}
            modalStatus={errorPost}
            messageShow
            message={message}
            titleModal="Faltan objetivos generales para crear evaluaciones"
          />
        ) : null}
      </ContainPeriodoFix>
      <BackWhiteQ>
        <ContainerTableFixed>

          <ContainerTable>
            <WrapperPeriodos>
              <WrapperPeriodosHeader>
                <div className="first">
                  <CellHeadingText>Año</CellHeadingText>
                </div>
                <div className="second">
                  <CellHeadingText>Período</CellHeadingText>
                </div>
                <div className="third">
                  <CellHeadingText>Evaluaciones creadas</CellHeadingText>
                </div>
                <div className="fourth">
                  <CellHeadingText>Evaluaciones por crear</CellHeadingText>
                </div>
                {evaluationAttribute.create_evaluations_button === 1 && (
                  <div className="fifth">
                    <CellHeadingText>Acción</CellHeadingText>
                  </div>
                )}
              </WrapperPeriodosHeader>

              {periods.map((item: IPeriod) => {
                const periodState = periodStates[item.evaluationPeriodId] || {
                  isInProgress: false,
                  isError: false,
                };

                let buttonLabel: React.ReactNode = 'Crear evaluaciones';
                let disabled = false;
                let buttonStyle: React.CSSProperties = {
                  border: 'none',
                  minWidth: '182px',
                };

                if (item.evaluationsForCreate === 0) {
                  disabled = true;
                  buttonStyle = {
                    background: "#c1c1c3",
                    color: "white",
                    cursor: "default",
                    border: 'none',
                    minWidth: '182px',
                  };
                } else if (periodState.isInProgress) {
                  buttonLabel = (
                    <>
                      En progreso <img style={{ width: "21px" }} src={colladoLoader} alt="" />
                    </>
                  );
                  disabled = true;
                } else if (periodState.isError) {
                  buttonLabel = 'Reanudar creación';
                  buttonStyle = {
                    border: 'none',
                    minWidth: '182px',
                    color: 'blue',
                  };
                  disabled = false;
                }

                return (
                  <WrapperPeriodosBody key={item.evaluationPeriodId}>
                    <div className="first">{item.evaluationPeriodYear}</div>
                    <div className="second">
                      {item.evaluationPeriodNumberOfYear}
                    </div>
                    <div className="third">{item.evaluationsCreates}</div>
                    <div className="fourth">{item.evaluationsForCreate}</div>

                    {evaluationAttribute.create_evaluations_button === 1 && (
                      <div className="fifth">
                        <button
                          className="btn"
                          disabled={disabled}
                          onClick={() => !disabled && activeCautionModal(item)}
                          style={buttonStyle}
                        >
                          {buttonLabel}
                        </button>
                      </div>
                    )}
                  </WrapperPeriodosBody>
                );
              })}
            </WrapperPeriodos>
          </ContainerTable>
          <PaginationBox style={{ marginTop: "40px" }}>
            <PaginationTable table="Periods" Meta={Meta} />
          </PaginationBox>
        </ContainerTableFixed>
      </BackWhiteQ>
      <Caution
        textBody="Al hacer clic en Continuar, estarás cerrando oficialmente el periodo anterior y comenzando uno nuevo para las evaluaciones. Ten en cuenta que esta acción no se puede deshacer."
        textBodyQuestion={'¿Deseas continuar?'}
        textBodyOk=""
        messageShow={true}
        title="Crear evaluaciones"
        estado={showModalCaution}
        cambiarEstado={setShowModalCaution}
        textButtonAct="Continuar"
        textButtonClose="Cancelar"
        handleClick={() => handleCreateEvaluation(periodId)}
      />
    </ContainPageQuestionaire>
  );
};

export default PeriodosEvaluaciones;
