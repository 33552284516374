/* eslint-disable react-hooks/exhaustive-deps */
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { useContext, useEffect, useState } from "react";
import {
  EvaluationNew,
  IFilterCollaborators,
  ListCollaborator,
} from "../../../Interface/ApiInterface";
import { ColaboratorHeaderEvaluation } from "../../../components/atoms/ColaborationHeaderEvaluation/ColaboratorHeaderEvaluation";
import ModalLoader from "../../../components/organisms/ConfirmationModals/ModalLoader";
import ModalStatusTargetError from "../../../components/organisms/ConfirmationModals/ModalStatusError";
import ModalStatusSuccessful from "../../../components/organisms/ConfirmationModals/ModalStatusSuccessful";
import "../../../components/organisms/DashboardCardsBox/Change.css";
import { DashboardCardBox } from "../../../components/organisms/DashboardCardsBox/DashboardCardsBox";
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../../context/EvaluationContext";
import {
  validateIdPeriod,
  validateSemestre,
} from "../../../context/validacionesFiltros";
import { useCollaboratorRedux } from "../../../redux/Collaborators";
import {
  getObjectivesPreviewList,
  getObjectivesPreviewListFuture,
} from "../../../services/dataSelects.service";
import { getColaborators } from "../../../services/organigrama.service";
import AgregarArchivos from "./components/AgregarArchivos/AgregarArchivos";
import LogActividad from "./components/LogActividad/LogActividad";
import ModalCambioStatusMasivo from "./components/ModalCambioStatusMasivo/ModalCambioStatusMasivo";
import ModalStatusActualizado from "./components/ModalStatusActualizado/ModalStatusActualizado";
import ProgresoEvaluaciones from "./components/ProgresoEvaluaciones/ProgresoEvaluaciones";

import { useNavigate } from "react-router-dom";
import Caution from "../../../components/organisms/ConfirmationModals/Caution";
import {
  FiltersDashboardContext,
  FiltersDashboardType,
} from "../../../context/ManageFiltersDashboard/FiltersDashboard";
import { useEvaluationRedux } from "../../../redux/Evaluations";
import { CambioDeEstatusMasivoSolicitud } from "../../../services/massiveChange.service";
import ModalTable from "../MySelect";
import NewFilterDashboard from "./NewComponentsDashboardRefactor/NewFilterDashboard";
import DownloadReportCollaborators from "./NewComponentsDashboardRefactor/ReporteColaboradores/DownloadReportCollaborators";
import TableColaboradores from "./NewComponentsDashboardRefactor/TablaDeColaboradores/TableColaboradores";
import DashboardOrganigrama, {
  SearchBarOrganigrama,
  WrapperOrganigrama,
  convertToTreeStructure,
} from "./NewComponentsDashboardRefactor/components/NodeDashboardOrganigrama";
import {
  AdminContainer,
  BackWhite,
  ButtonCambiarStatus,
  ContainerButtonCambio,
} from "./stylesDashboard/AdminStyles";
import { ContainerDashboard } from "./stylesDashboard/NewStylesDashboard";
import { useLoader } from "../../../context/LoaderContext";

interface DataDonuts {
  title: string;
  value: any;
}

interface TreeNode {
  label: string;
  selectable: boolean;
  children: TreeNode[];
  idStatus: number;
  claTrab: number;
}

const Dashboard = () => {
  //** Redux
  const {
    Meta,
    User,
    handleGetCollaborators,
    errorloading,
    setErrorLoading,
    Filters,
    evaluationAttribute,
    handleGetCollaborator,
    Collaborator,
  } = useCollaboratorRedux();

  //** Context
  const { getOptionsDirections, getOptionisLocations, locations } = useContext(
    FiltersDashboardContext
  ) as FiltersDashboardType;


  //** Context
  const {
    loadingPagination,
    setLoadingPagination,
    setChangePage,
    setFutureObjetives,
    setEvaluationInfo,
    setPeriodId,
    changePage,
  } = useContext(EvaluationContext) as EvaluationContextType;
  const { handleGetEvaluation } = useEvaluationRedux();

  const [propLocation, setPropLocation] = useState<string>("");
  const [propDirector, setPropDirector] = useState<string>("");

  const [nameEmployee, setNameEmployee] = useState<string>("");
  const [searchValue, setSearchValue] = useState("");
  const [semestre, setSemestre] = useState("");
  const [evaluation_period_id, setEvaluation_period_id] = useState<string>("");
  const [filters, setFilters] = useState({} as IFilterCollaborators);
  const [showModalCambioStatus, setShowModalCambioStatus] =
    useState<boolean>(false);
  const [showModalEstatusActualizado, setShowModalEstatusActualizado] =
    useState<boolean>(false);
  const [selectedNodeValue, setSelectedNodeValue] = useState<string | null>(
    null
  );
  const [selection, setSelection] = useState<any>();

  const [updateTable, setUpdateTable] = useState(false);
  const [isSelectLocalidadUpdated, setIsSelectLocalidadUpdated] =
    useState(false);
  const [evaluation, setEvaluation] = useState<EvaluationNew>(
    {} as EvaluationNew
  );
  const [objetivos, setObjetivos] = useState<string[]>([]);
  const [show, setShow] = useState(false);
  const [showBookInterview, setshowBookInterview] = useState(false);

  //!Node data
  const [idNodeStatus, setIdNodeStatus] = useState<number>(0);
  const [idPeriodNode, setIdPeriodNode] = useState<number>(0);
  //!Node data

  const [dataGraph, setDataGraph] = useState<Array<DataDonuts>>([
    {
      title: "",
      value: "",
    },
  ]);
  const [dataRestante, setDataRestante] = useState<Array<DataDonuts>>([
    {
      title: "",
      value: "",
    },
  ]);
  const [error, setError] = useState(false);
  const [errorLoader, setErrorLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [filterSearch, setFilterSearch] = useState("");

  //! Query para filtros
  const [queryPeticionWithFilters, setQueryPeticionWithFilters] = useState("");

  //!Toggle
  const [hideOrganigrama, setHideOrganigrama] = useState(false);
  const [hideReporteSection, setHideReporteSection] = useState(false);
  const [hideAnaliticaSection, setHideAnaliticaSection] = useState(true);

  //!Toggle

  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [selectedStatusActual, setSelectedStatusActual] = useState<string>("");
  const [catStatusActual, setCatStatusActual] = useState<number | null>(null);

  // Estado para catStatusEvaluationId del estatus actual
  const [catStatusNuevo, setCatStatusNuevo] = useState<number | null>(null);
  const [listStringLocalidad, setListStringLocalidad] = useState<string | null>(
    ""
  );
  // Estado para catStatusEvaluationId del estatus nuevo
  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [showModalCaution, setShowModalCaution] = useState(false);
  const [collabData, setCollabData] = useState<any>();

  const periodo = validateIdPeriod(evaluation_period_id);

  const changeInfoTableGeneral = (changeMasive?: boolean) => {
    const pageStorage = localStorage.getItem("pageLocalStorage");
    const pageStorageParsed = JSON.parse(pageStorage ?? "{}");

    const { page: pageStore } = pageStorageParsed;

    if (evaluation_period_id) {
      if (changeMasive) {
        if (queryPeticionWithFilters === "") {
          handleGetCollaborators(
            `?NombreColaborador=${User.nombre}&RolColaborador=${User.role === "Auditor" ? "Admin" : User.role
            }&IdPeriod=${periodo}&PageNumber=${pageStore ? pageStore : 1
            }&PageSize=10`
          );
        } else {
          handleGetCollaborators(queryPeticionWithFilters);
        }
      } else {
        const periodo = validateIdPeriod(evaluation_period_id);
        // setLoadingPagination(true)
        handleGetCollaborators(
          `?NombreColaborador=${User.nombre}&RolColaborador=${User.role === "Auditor" ? "Admin" : User.role
          }&IdPeriod=${periodo}&PageNumber=${pageStore ? pageStore : 1
          }&PageSize=10`
        );
      }
    }
  };

  const changeInfoTableOrganigrama = () => {
    const pageStorage = localStorage.getItem("pageLocalStorage");
    const pageStorageParsed = JSON.parse(pageStorage ?? "{}");

    const { page: pageStore } = pageStorageParsed;

    let query = `?`;

    if (nameEmployee !== undefined && nameEmployee !== "") {
      filters.JerarquiaColaborador = nameEmployee;
      query += `&JerarquiaColaborador=${filters.JerarquiaColaborador}`;
    }

    const periodo = validateIdPeriod(evaluation_period_id);
    query += `&NombreColaborador=${User.nombre}&RolColaborador=${User.role === "Auditor" ? "Admin" : User.role
      }&IdPeriod=${periodo}&PageSize=10&PageNumber=${pageStore ? pageStore : 1}`;
    // setLoadingPagination(true);
    handleGetCollaborators(query);
  };

  const handleSelectPeriod = (e: any) => {
    setEvaluation_period_id(e);
  };

  useEffect(() => {
    getOptionisLocations();
    getOptionsDirections();
  }, []);

  // console.log("Datos de la grafica", dataGraph);

  useEffect(() => {
    const pageStorage = localStorage.getItem("pageLocalStorage");
    const pageStorageParsed = JSON.parse(pageStorage ?? "{}");

    const { page: pageStore } = pageStorageParsed;

    if (evaluation_period_id) {
      // setLoadingPagination(true);
      const periodo = validateIdPeriod(evaluation_period_id);
      const queryParts: string[] = [];

      for (const key in Filters) {
        if (Filters[key] !== undefined && Filters[key] !== "") {
          if (
            key === "Mas95Porcent" ||
            key === "Menos50Porcent" ||
            key === "TienePoliticPorcent" ||
            key === "SinEvaluacionPorcent"
          ) {
            queryParts.push(`GraphicFilter.${key}=${true}`);
          } else {
            queryParts.push(`${key}=${Filters[key]}`);
          }
        }
      }

      let queryFilters = queryParts.join("&");

      handleGetCollaborators(
        `?${queryFilters}&NombreColaborador=${User.nombre}&RolColaborador=${User.role === "Auditor" ? "Admin" : User.role
        }&IdPeriod=${periodo}&PageNumber=${pageStore ? pageStore : 1
        }&PageSize=10`
      );
    }
  }, [evaluation_period_id, updateTable]);

  const GetColaboradores = async (grlParam: string, idPeriod: any) => {
    try {
      const response = await getColaborators(grlParam.toUpperCase(), idPeriod);
      if (response) {
        // console.log("Aqui esta la respuesta organigrama", response);
        return response.data; // Extract the data from the AxiosResponse object.
      }
    } catch (error) {
      return null;
    }
  };

  const fetchData = async (value: string, idPeriod: any) => {
    const collaboratorsData = await GetColaboradores(value, idPeriod);
    if (Array.isArray(collaboratorsData)) {
      const orgChart: TreeNode[] = convertToTreeStructure(collaboratorsData);
      setCollabData(orgChart);
    }
  };

  const navigate = useNavigate();
  const handleShow = () => setShow(true);
  const handleInterviewModal = () => setshowBookInterview(true);

  useEffect(() => {
    const periodLocal = localStorage.getItem("periodId");
    const periodParsed = JSON.parse(periodLocal ?? "{}");
    const { valuePeriod } = periodParsed;

    const filtrosAlmacenados = localStorage.getItem("principalFilters");
    const filtros = JSON.parse(filtrosAlmacenados ?? "{}");
    const { year, periodo, propLocation, propDirector } = filtros;

    const getFutureObjectives = async (collaborator: ListCollaborator) => {
      handleGetCollaborator(collaborator.claTrab);

      handleGetEvaluation(User.claTrab, collaborator.claTrab, periodo);

      const { data: futureObjectives } = await getObjectivesPreviewListFuture(
        periodo,
        collaborator?.employeeEvaluation.employeeEvaluationEvaluatedUserId,
        idPeriodNode
      );

      setEvaluationInfo(collaborator);
      setFutureObjetives(futureObjectives);

      navigate(
        `/evaluacion/${collaborator.claTrab}/${year}/${validateSemestre(
          valuePeriod
        )}`
      );
    };

    const handleActionButton = async () => {
      switch (idNodeStatus) {
        case 1:
          const { data: currentObjectives } = await getObjectivesPreviewList(
            periodo,
            Collaborator?.employeeEvaluation.employeeEvaluationEvaluatedUserId,
            idPeriodNode
          );
          setEvaluation(Collaborator.employeeEvaluation);
          setPeriodId(idPeriodNode);
          setChangePage(!changePage);
          setObjetivos(currentObjectives);
          handleShow();
          break;

        case 2:
        case 3:
        case 4:
          setChangePage(!changePage);
          getFutureObjectives(Collaborator as unknown as ListCollaborator);
          break;

        case 5:
          setChangePage(!changePage);
          setEvaluation(Collaborator.employeeEvaluation);
          handleInterviewModal();
          break;

        case 6:
        case 7:
          setChangePage(!changePage);
          getFutureObjectives(Collaborator as unknown as ListCollaborator);
          break;
      }
    };
    handleActionButton();
  }, [Collaborator]);

  useEffect(() => {
    if (selection?.children.length === 0) {
      setSelectedNodeValue(selection?.label);
    }
  }, [selection]);

  useEffect(() => {
    const pageStorage = localStorage.getItem("pageLocalStorage");
    const pageStorageParsed = JSON.parse(pageStorage ?? "{}");

    const { page: pageStore } = pageStorageParsed;

    if (selection) {
      if (evaluation_period_id) {
        const periodo = validateIdPeriod(evaluation_period_id);
        // setLoadingPagination(true);
        handleGetCollaborators(
          `?NombreColaborador=${selection?.label
          }&RolColaborador=evaluador&IdPeriod=${periodo}&PageNumber=${pageStore ? pageStore : 1
          }&PageSize=10&isNode=${true}`
        );
      }
    }
  }, [selection]);

  const handleClearSelection = () => {
    const pageStorage = localStorage.getItem("pageLocalStorage");
    const pageStorageParsed = JSON.parse(pageStorage ?? "{}");

    const { page: pageStore } = pageStorageParsed;

    const periodo = validateIdPeriod(evaluation_period_id);
    fetchData(User.nombre, periodo);

    if (evaluation_period_id) {
      const periodo = validateIdPeriod(evaluation_period_id);
      setSearchValue("");
      // setLoadingPagination(true);
      handleGetCollaborators(
        `?NombreColaborador=${User.nombre}&RolColaborador=${User.role === "Auditor" ? "Admin" : User.role
        }&IdPeriod=${periodo}&PageNumber=${pageStore ? pageStore : 1
        }&PageSize=10`
      );
    }
  };

  const handleSelectOrganigrama = (e: any) => {
    const pageStorage = localStorage.getItem("pageLocalStorage");
    const pageStorageParsed = JSON.parse(pageStorage ?? "{}");

    const { page: pageStore } = pageStorageParsed;

    setSearchValue(e.target.value);
    const periodo = validateIdPeriod(evaluation_period_id);
    fetchData(e.target.value, periodo);
    // setLoadingPagination(true);
    handleGetCollaborators(
      `?NombreColaborador=${e.target.value
      }&RolColaborador=evaluador&IdPeriod=${periodo}&PageNumber=${pageStore ? pageStore : 1
      }&PageSize=10&isNode=${true}`
    );
  };

  const handleClickNode = (e: any) => {
    setSelection(e.data);
    const period = validateIdPeriod(evaluation_period_id);
    fetchData(e.data.label, period);
  };

  //!!  NO TOCAR ES EL LOADER PAGINADO

  // useEffect(() => {
  //   setLoadingPagination(false);
  // }, [Meta]);

  useEffect(() => {
    if (errorloading === 403 || errorloading) {
      // setLoadingPagination(false);
      setErrorLoader(true);
      setErrorLoading(null);
    }
  }, [errorloading]);

  const handleCambiarEstatus = async () => {
    try {
      // const userIdArray = userId.split(",").map((id) => id.trim());
      // const result = await getStatusChange({
      //   catStatusActual,
      //   catStatusNuevo,
      //   idPeriod: periodo,
      //   selectedLocation,
      //   userId: userIdArray.join(","),
      // });
      CambioDeEstatusMasivoSolicitud({
        estatusActual: catStatusActual,
        estatusNuevo: catStatusNuevo,
        EvaluationPeriodId: Number(periodo) === 0 ? User.periodInfo.idPeriod : Number(periodo),
        claTrabSolicitud: User.claTrab,
        listLocalidad: listStringLocalidad,
      })


      setShowModalEstatusActualizado(!showModalEstatusActualizado);
    } catch (error) {
      // console.error("Error al cambiar el estatus:", error);
    }
  };

  const { isLoading } = useLoader();



  // console.log("Lista  ", listStringLocalidad)
  return (
    <AdminContainer>
      <ContainerDashboard>
        <ColaboratorHeaderEvaluation
          hide={hideAnaliticaSection}
          setHide={setHideAnaliticaSection}
        />

        {hideAnaliticaSection && (
          <div style={{ marginTop: "24px" }}>
            <NewFilterDashboard
              setSemestre={setSemestre}
              handleSelectPeriod={handleSelectPeriod}
              setEvaluation_period_id={setEvaluation_period_id}
              propDirector={propDirector}
              propLocation={propLocation}
              setPropLocation={setPropLocation}
              setPropDirector={setPropDirector}
              setDataGraph={setDataGraph}
              setDataRestante={setDataRestante}
              dataGraph={dataGraph}
              fetchData={fetchData}
              // handlePaintData={handlePaintData}
              setIsSelectLocalidadUpdated={setIsSelectLocalidadUpdated}
              isSelectLocalidadUpdated={isSelectLocalidadUpdated}
            />

            {/* ++++++++++++++++++++++++ New filter +++++++++++++++++++++++++ */}
            {evaluationAttribute.evaluations_progress === 1 && (
              <ProgresoEvaluaciones
                idPeriod={evaluation_period_id}
                name={User.nombre}
                role={User.role}
              />
            )}
            {isSelectLocalidadUpdated && (
              <DashboardCardBox
                firstValidation={3}
                secondValidation={4}
                evaluation_period_id={evaluation_period_id}
                dataDonutComplete={dataGraph}
                dataDonutRestante={dataRestante}
                inPolitics={false}
              />
            )}
          </div>
        )}
      </ContainerDashboard>

      {evaluationAttribute.show_organigrama === 1 && (
        <WrapperOrganigrama hContainer={hideOrganigrama}>
          <SearchBarOrganigrama
            setHide={setHideOrganigrama}
            hide={hideOrganigrama}
            searchValue={searchValue}
            handleSelectOrganigrama={handleSelectOrganigrama}
            handleClearSelection={handleClearSelection}
          />
          {hideOrganigrama && (
            <>
              {collabData && (
                <DashboardOrganigrama
                  setIdNodeStatus={setIdNodeStatus}
                  handleClickNode={handleClickNode}
                  selection={selection}
                  setIdPeriodNode={setIdPeriodNode}
                  collabData={collabData}
                />
              )}
            </>
          )}
        </WrapperOrganigrama>
      )}

      {evaluationAttribute.module_ratings === 1 && <AgregarArchivos />}

      <BackWhite>
        <DownloadReportCollaborators
          evaluation_period_id={evaluation_period_id}
          hide={hideReporteSection}
          setHide={setHideReporteSection}
        />

        {hideReporteSection && (
          <div>
            {evaluationAttribute.search_filter === 1 && (
              <TableColaboradores
                evaluation_period_id={evaluation_period_id}
                nameEmployee={nameEmployee}
                setFilterSearch={setFilterSearch}
                setErrorLoader={setErrorLoader}
                setQueryPeticionWithFilters={setQueryPeticionWithFilters}
                changeInfoTableGeneral={changeInfoTableGeneral}
                semestre={semestre}
                filterSearch={filterSearch}
                setError={setError}
                setSuccess={setSuccess}
                setMessage={setMessage}
                updateTable={updateTable}
                setUpdateTable={setUpdateTable}
                setNameEmployee={setNameEmployee}
                changeInfoTableOrganigrama={changeInfoTableOrganigrama}
              />
            )}
            {/* ++++++++++++++++++++++++++++CAMBIO MASIVO DE ESTATUS +++++++++++++++++++++++++++++++ */}
            {evaluationAttribute?.change_massive_status_button === 1 && (
              <ContainerButtonCambio>
                <ButtonCambiarStatus
                  onClick={() =>
                    setShowModalCambioStatus(!showModalCambioStatus)
                  }
                >
                  Cambio de estatus masivo
                </ButtonCambiarStatus>
              </ContainerButtonCambio>
            )}
          </div>
        )}
      </BackWhite>

      {evaluationAttribute?.btn_download_global_logs === 1 && <LogActividad />}

      {/* ++++++++++++++++++++++++++++MODALES +++++++++++++++++++++++++++++++ */}
      {success && (
        <ModalStatusSuccessful
          handleClick={setSuccess}
          modalStatus={success}
          messageShow
          message={message}
        />
      )}
      {error && (
        <ModalStatusTargetError
          handleClick={setError}
          modalStatus={error}
          messageShow
          message={message}
        />
      )}

      <ModalTable
        evaluation={evaluation}
        show={show}
        onHide={setShow}
        semestre={semestre}
        objetivos={objetivos}
      />

      {showModalCambioStatus && (
        <ModalCambioStatusMasivo
          idPeriod={periodo}
          locations={locations}
          setShowModalCambioStatus={setShowModalCambioStatus}
          showModalCambioStatus={showModalCambioStatus}
          showModalEstatusActualizado={showModalEstatusActualizado}
          setShowModalEstatusActualizado={setShowModalEstatusActualizado}
          setSelectedStatus={setSelectedStatus}
          setCatStatusNuevo={setCatStatusNuevo}
          setSelectedLocation={setSelectedLocation}
          setCatStatusActual={setCatStatusActual}
          setUserId={setUserId}
          selectedLocation={selectedLocation}
          selectedStatus={selectedStatus}
          userId={userId}
          setShowModalCaution={setShowModalCaution}
          setSelectedStatusActual={setSelectedStatusActual}
          setListStringLocalidad={setListStringLocalidad}
        />
      )}
      <Caution
        textBody={`Al confirmar, cambiarás el estatus ${selectedStatus} a ${selectedStatusActual} para los usuarios seleccionados.`}
        textBodyQuestion="¿Deseas continuar?"
        messageShow={true}
        title="Cambio de estatus masivo"
        estado={showModalCaution}
        cambiarEstado={setShowModalCaution}
        textButtonAct="Continuar"
        textButtonClose="Cancelar"
        handleClick={handleCambiarEstatus}
      />
      {showModalEstatusActualizado && (
        <ModalStatusActualizado
          getAllInfo={changeInfoTableGeneral}
          showModalEstatusActualizado={showModalEstatusActualizado}
          setShowModalEstatusActualizado={setShowModalEstatusActualizado}
          setListStringLocalidad={setListStringLocalidad}
        />
      )}
      {/* {loadingPagination && <ModalLoader />} */}
      {isLoading && <ModalLoader />}
      {errorLoader && (
        <ModalStatusTargetError
          handleClick={setErrorLoader}
          modalStatus={errorLoader}
          messageShow
          message="Error en la carga de datos"
        />
      )}
    </AdminContainer>
  );
};

export default Dashboard;
